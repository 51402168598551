import 'ol/ol.css';
import GeoJSON from 'ol/format/GeoJSON';
import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import View from 'ol/View';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Polyline from 'ol/format/Polyline';
import { Style, Stroke, Icon, Fill, Text } from 'ol/style';
import TileLayer from 'ol/layer/Tile';
//import XYZSource from 'ol/source/XYZ';
import { fromLonLat, toLonLat, transform } from 'ol/proj';
import sync from 'ol-hashed';
import Overlay from 'ol/Overlay';
import { click, pointerMove } from 'ol/events/condition';
import Select from 'ol/interaction/Select';
//import { toStringHDMS, toStringXY } from 'ol/coordinate.js';


var closer = document.getElementById('popup-closer'),
    container = document.getElementById('popup'),
    //content = document.getElementById('popup-content'),
    site_url = '//nationaltrails.grandad.digital',
    //site_url = '//localhost/maps',
    url_route = 'https://api.mapbox.com/directions/v5/mapbox/walking/',
    mapbox_api_token = 'pk.eyJ1Ijoic2FtLWdyYW5kYWRsb25kb24iLCJhIjoiY2pqNnQ4YXV0MDB0MjNrbnpzaDR0NWc4ZiJ9.j-LdlDGOcQZJ2l5kxF3EtA',
    start_icon_url = '//map.project-osrm.org/images/marker-start-icon-2x.png',
    end_icon_url = '//map.project-osrm.org/images/marker-end-icon-2x.png',
    place_icon_url = '//cdn.rawgit.com/openlayers/ol3/master/examples/data/icon.png',
    vectorSource = new VectorSource(),
    vectorLayer = new VectorLayer({
        source: vectorSource
    }),
    featureSource = new VectorSource(),
    featureLayer = new VectorLayer({
        source: featureSource
    }),
    styles = {
        route: new Style({
            stroke: new Stroke({
                width: 6, color: [40, 40, 40, 0.8]
            })
        }),
        start_icon: new Icon({
            anchor: [0.5, 0.5],
            src: start_icon_url
        }),
        end_icon: new Icon({
            anchor: [0.5, 0.5],
            src: end_icon_url
        }),
        place_icon: new Icon({
            anchor: [0.5, 1],
            src: place_icon_url
        }),
        lodging_icon: new Icon({
            anchor: [0.5, 1],
            src: site_url + '/assets/img/lodging.png'
        })
    },
    feature_types = {
        restaurant: {
            name: 'Restaurants',
            type: 'restaurant',
            icon: new Icon({
                anchor: [0.5, 1],
                src: site_url + '/assets/img/restaurant.png'
            })
        },
        lodging: {
            name: 'Hotels',
            type: 'lodging',
            icon: new Icon({
                anchor: [0.5, 1],
                src: site_url + '/assets/img/lodging.png'
            })
        },
        bar: {
            name: 'Pubs',
            type: 'bar',
            icon: new Icon({
                anchor: [0.5, 1],
                src: site_url + '/assets/img/bar.png'
            })
        }
    },
    selectPointerMove = new Select({
        condition: pointerMove
    }),
    selectClick = new Select({
        condition: click
    }),
    place_template = $('.place_details').remove();

var points = [],
    msg_el = document.getElementById('msg'),
    current_feature_type = feature_types.bar,
    last_text,
    clicked_feature = false;

var initial_coords = fromLonLat([-2.145, 51.7169]),
    initial_zoom = 10;

var map = new Map({
    target: 'map',
    layers: [
        new TileLayer({
            source: new OSM()
        }),
        vectorLayer,
        featureLayer
    ],
    view: new View({
        center: initial_coords,
        zoom: initial_zoom
    })
});

var overlay = new Overlay({
    element: container,
    autoPan: true,
    autoPanAnimation: {
        duration: 250
    }
});
map.addOverlay(overlay);

map.on('singleclick', function (evt) {

    if (clicked_feature) {
        clicked_feature = false;
        return;
    }

    var coordinate = evt.coordinate;
    var lonlat = toLonLat(coordinate);

    /*var coord_string = toStringXY(toLonLat(coordinate), 3);

    content.innerHTML = '<p>You clicked here:</p><code>' + coord_string + '</code>';
    overlay.setPosition(coordinate);*/


    var last_point = points[points.length - 1],
        points_length = points.push(lonlat),
        icon_style = points_length < 2 ? styles.start_icon : styles.end_icon;

    utils.createMarker(vectorSource, coordinate, icon_style);

    if (points_length < 2) {
        msg_el.innerHTML = 'Click to add another point';
        return;
    }

    //get the route
    var point1 = last_point.join(',');
    var point2 = lonlat.join(',');
    var url = url_route + point1 + ';' + point2 + '.json?access_token=' + mapbox_api_token;

    fetch(url).then(function (r) {
        return r.json();
    }).then(function (json) {
        if (json.code !== 'Ok') {
            msg_el.innerHTML = 'No route found.';
            return;
        }
        msg_el.innerHTML = 'Route added';
        //points.length = 0;
        utils.createRoute(json.routes[0].geometry);

        var duration = Math.ceil(json.routes[0].duration / 60),
            time_units = ' minutes',
            distance = Math.round((json.routes[0].distance / 1000 * 0.621371) * 10) / 10,
            distance_units = ' miles';

        if (duration > 60) {
            time_units = ' hours';
            duration = Math.round((duration / 60) * 10) / 10;

            if (duration > 24) {
                time_units = ' days';
                duration = Math.round((duration / 24) * 10) / 10;
            }
        }

        $('.route_distance').text(distance + distance_units);
        $('.route_duration').text(duration + time_units);

        show_features(lonlat, current_feature_type);
    });
});

map.addInteraction(selectPointerMove);
map.addInteraction(selectClick);

selectPointerMove.on('select', function (evt) {

    var features = evt.target.getFeatures(),
        feature = features.item(0);

    if (!feature)
        return;

    var style = feature.getStyle();

    if (!style)
        return;
    
    var text = style.getText();

    if (!text)
        return;

    if (last_text)
        last_text.setText('');

    text.setText(feature.get('name'));
    feature.setStyle(style);

    last_text = text;
});

selectClick.on('select', function (evt) {

    var features = evt.target.getFeatures(),
        feature = features.item(0);

    if (!feature)
        return;

    var id = feature.get('id');

    if (!id)
        return;

    clicked_feature = true;

    show_details(id);
})

closer.onclick = function () {
    overlay.setPosition(undefined);
    closer.blur();
    return false;
};

function show_details(id) {

    $('.place_details').remove();

    var place_elem = place_template.clone();

    var url = site_url + '/api/details/?id=' + id;

    fetch(url).then(function (r) {

        return r.json();

    }).then(function (json) {

        if (json) {

            if (!json.result)
                return;

            if (json.result.name)
                $('.place_name', place_elem).text(json.result.name);

            if (json.result.formatted_address)
                $('.place_address', place_elem).text(json.result.formatted_address);

            if (json.result.formatted_phone_number)
                $('.place_phone', place_elem).text(json.result.formatted_phone_number);

            if (json.result.rating)
                $('.place_rating', place_elem).text(json.result.rating);

            if (json.result.website) {
                $('.place_website', place_elem).text(json.result.website);
                $('.place_website', place_elem).attr('href', json.result.website);
            }

            if (json.result.opening_hours && json.result.opening_hours.weekday_text) {
                var hours = '';
                json.result.opening_hours.weekday_text.forEach(line => {
                    hours += line + '<br/>';
                });
                $('.place_hours', place_elem).html(hours);
            }

            $('#info').append(place_elem);
        }
    });
}

function show_features(lonlat, feature_type) {

    var radius = $('input[name=radius]').val(),
        url = site_url + '/api/nearbysearch/?radius=' + radius + '&type=' + feature_type.type + '&location=' + lonlat[1] + ',' + lonlat[0];

    featureSource.clear();

    fetch(url).then(function (r) {

        return r.json();

    }).then(function (json) {

        if (json && json.results) {

            json.results.forEach(result => {

                utils.createFeature(featureSource, result, feature_type.icon);

            });
        }
    });
}

function add_main_route_layer(filename) {
    
    var url = site_url + '/data/' + filename;

    fetch(url).then(function (r) {

        return r.json();

    }).then(function (json) {

        if (json) {

            var route = (new GeoJSON().readFeature(json.features[0], {
              dataProjection: 'EPSG:4326',
              featureProjection: 'EPSG:3857'
            })).getGeometry();

            var route_feature = new Feature({
                    type: 'route',
                    geometry: route
                }),
                route_style = new Style({
                    stroke: new Stroke({
                      width: 3, color: 'blue'
                    })
                });
            
            var main_route_layer = new VectorLayer({
                source: new VectorSource({
                    features: [route_feature]
                }),
                style: route_style
            });

            map.addLayer(main_route_layer);
        }
    });
}

add_main_route_layer('cotswald_way.json');

sync(map);

function goto_user_location() {

    navigator.geolocation.getCurrentPosition(function (pos) {

        var location_coords = fromLonLat([pos.coords.longitude, pos.coords.latitude]);

        var marker = new Overlay({
            position: location_coords,
            positioning: 'center-center',
            element: document.getElementById('marker'),
            stopEvent: false
        });

        map.addOverlay(marker);

        setTimeout(function () {

            map.getView().animate({ center: location_coords, zoom: 15, duration: 500 });
        }, 500);
    });
}

$('#controls input[name=show_features]').change(function () {

    current_feature_type = feature_types[$(this).val()];

    if (!points.length)
        return;

    var last_point = points[points.length - 1];

    show_features(last_point, current_feature_type);
});

$('#controls input[name=radius]').change(function () {

    if (!points.length)
        return;

    var last_point = points[points.length - 1];

    show_features(last_point, current_feature_type);
});



var utils = {

    getNearest: function (coord) {

        var coord4326 = utils.to4326(coord);
        return new Promise(function (resolve, reject) {
            //make sure the coord is on street
            fetch(url_osrm_nearest + coord4326.join()).then(function (response) {
                // Convert to JSON
                return response.json();
            }).then(function (json) {
                if (json.code === 'Ok') resolve(json.waypoints[0].location);
                else reject();
            });
        });
    },
    createMarker: function (source, coord, icon_style) {

        var feature = new Feature({
            type: 'icon',
            geometry: new Point(coord)
        });
        feature.setStyle(new Style({
            image: icon_style
        }));
        source.addFeature(feature);
        return feature;
    },
    createFeature: function (source, result, icon_style) {

        var feature = new Feature({
            type: 'icon',
            geometry: new Point(utils.to3857(result.geometry.location)),
            name: result.name,
            id: result.place_id
        }),
            style = new Style({
                image: icon_style,
                text: new Text({
                    font: 'bold 11px Arial',
                    placement: 'point',
                    textBaseline: 'bottom',
                    fill: new Fill({
                        color: 'black'
                    }),
                    stroke: new Stroke({
                        color: 'white',
                        width: 3
                    })
                })
            });

        feature.setStyle(style);
        source.addFeature(feature);

        return feature;
    },
    createRoute: function (polyline) {

        var route = new Polyline({
            factor: 1e5
        }).readGeometry(polyline, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
        });
        var feature = new Feature({
            type: 'route',
            geometry: route
        });
        feature.setStyle(styles.route);
        vectorSource.addFeature(feature);
    },
    to4326: function (coord) {

        return transform([
            parseFloat(coord[0]), parseFloat(coord[1])
        ], 'EPSG:3857', 'EPSG:4326');
    },
    to3857: function (coord) {

        return transform([
            parseFloat(coord.lng), parseFloat(coord.lat)
        ], 'EPSG:4326', 'EPSG:3857');
    }
};